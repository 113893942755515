import dynamic from "next/dynamic"
import { useEffect, useRef, useState } from "react"

const ReactPlayer = dynamic(() => import("react-player/lazy"), { ssr: false })

export default function DemoPayOnchainVideo() {
  const [isVisible, setIsVisible] = useState(false)
  const elementRef = useRef(null)

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      // The callback will return an array of entries, even if you are observing a single item
      const [entry] = entries
      // Set state based on whether the element is in the viewport
      setIsVisible(entry.isIntersecting)
    })

    if (elementRef.current) {
      observer.observe(elementRef.current)
    }

    return () => {
      if (elementRef.current) {
        observer.unobserve(elementRef.current)
      }
    }
  }, [])

  return (
    <div
      ref={elementRef}
      className="w-80 rounded-xl overflow-hidden shadow border border-border"
    >
      <ReactPlayer
        url={process.env.NEXT_PUBLIC_APP_URL + "/videos/checkout-demo.mp4"}
        width={320}
        height={569}
        volume={0}
        muted
        loop
        playing={isVisible}
        playsinline
      />
      {/* <video
        // width={320}
        // height={569}
        // volume={0}
        muted
        loop
        autoPlay
        playsInline
      >
        <source
          src={process.env.NEXT_PUBLIC_APP_URL + "/videos/checkout-demo.mp4"}
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video> */}
    </div>
  )
}
