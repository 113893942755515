import { darkColorList } from "@utils/colorList"
import { useAppContext } from "@components/ui/context"

const HomeEth = ({ ...props }) => {
  const { color1, color2 } = useAppContext()

  return (
    <div className="relative flex items-center justify-center w-72 h-72 group">
      <svg
        className="absolute transition-opacity duration-200 opacity-0 w-28 h-28 group-hover:opacity-75"
        viewBox="0 0 798 1299"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M398.409 0L389.701 29.5797V887.837L398.409 896.525L796.795 661.036L398.409 0Z"
          fill={color2[0]}
        />
        <path
          d="M398.396 0L0 661.036L398.396 896.525V479.951V0Z"
          fill={color2[0]}
        />
        <path
          d="M398.412 971.988L393.504 977.973V1283.7L398.412 1298.03L797.041 736.621L398.412 971.988Z"
          fill={color2[0]}
        />
        <path
          d="M398.396 1298.03V971.988L0 736.621L398.396 1298.03Z"
          fill={color2[0]}
        />
        <path
          d="M398.416 896.511L796.802 661.022L398.416 479.937V896.511Z"
          fill={color2[0]}
        />
        <path
          d="M0 661.022L398.396 896.511V479.937L0 661.022Z"
          fill={color2[0]}
        />
      </svg>
      <svg
        className="absolute w-24 h-24 opacity-80 drop-shadow-random-strong animate-ping-slow"
        viewBox="0 0 798 1299"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M398.409 0L389.701 29.5797V887.837L398.409 896.525L796.795 661.036L398.409 0Z"
          fill={color2[0]}
        />
        <path
          d="M398.396 0L0 661.036L398.396 896.525V479.951V0Z"
          fill={color2[0]}
        />
        <path
          d="M398.412 971.988L393.504 977.973V1283.7L398.412 1298.03L797.041 736.621L398.412 971.988Z"
          fill={color2[0]}
        />
        <path
          d="M398.396 1298.03V971.988L0 736.621L398.396 1298.03Z"
          fill={color2[0]}
        />
        <path
          d="M398.416 896.511L796.802 661.022L398.416 479.937V896.511Z"
          fill={color2[0]}
        />
        <path
          d="M0 661.022L398.396 896.511V479.937L0 661.022Z"
          fill={color2[0]}
        />
      </svg>
      <svg
        className="absolute w-24 h-24 drop-shadow-random-strong"
        viewBox="0 0 798 1299"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M398.914 0L390.206 29.5797V887.837L398.914 896.525L797.3 661.036L398.914 0Z"
          fill="#B8FAF6"
        />
        <path
          d="M398.901 0L0.505127 661.036L398.901 896.525V479.951V0Z"
          fill="#F0CDC2"
        />
        <path
          d="M398.917 971.989L394.009 977.974V1283.7L398.917 1298.03L797.546 736.621L398.917 971.989Z"
          fill="#C9B3F5"
        />
        <path
          d="M398.901 1298.03V971.989L0.505127 736.621L398.901 1298.03Z"
          fill="#F0CDC2"
        />
        <path
          d="M398.921 896.511L797.307 661.022L398.921 479.937V896.511Z"
          fill="#C9B3F5"
        />
        <path
          d="M0.505127 661.022L398.901 896.511V479.937L0.505127 661.022Z"
          fill="#88AAF1"
        />
      </svg>
      <svg
        width="2388"
        height="2469"
        viewBox="0 0 2388 2469"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="opacity-80 h-auto w-auto spin-el"
      >
        <path
          d="M976.492 94.0958L1174.71 437.56C1185.48 456.226 1212.42 456.226 1223.2 437.56L1421.56 93.8343C1434.09 72.1212 1428.11 45.1453 1405.04 35.3381C1367.5 19.3807 1301 3.243e-05 1199.46 0C1097.89 -3.24363e-05 1031.04 19.3883 993.214 35.3475C969.996 45.1439 963.896 72.2696 976.492 94.0958Z"
          fill={color2[0]}
        />
        <path
          d="M1411.4 2374.4L1213.19 2030.94C1202.41 2012.27 1175.47 2012.27 1164.7 2030.94L966.334 2374.67C953.803 2396.38 959.784 2423.35 982.856 2433.16C1020.4 2449.12 1086.89 2468.5 1188.44 2468.5C1290.01 2468.5 1356.86 2449.11 1394.68 2433.15C1417.9 2423.36 1424 2396.23 1411.4 2374.4Z"
          fill={color2[0]}
        />
        <path
          d="M2072.62 475.851L1874.28 819.244C1863.5 837.906 1876.97 861.237 1898.53 861.233L2295.38 861.162C2320.45 861.158 2340.83 842.49 2337.78 817.606C2332.83 777.116 2316.37 709.839 2265.59 621.896C2214.81 533.936 2164.59 485.735 2131.86 460.958C2111.77 445.748 2085.23 454.029 2072.62 475.851Z"
          fill={color2[0]}
        />
        <path
          d="M315.274 1992.65L513.614 1649.26C524.394 1630.59 510.923 1607.26 489.372 1607.27L92.5126 1607.34C67.443 1607.34 47.0717 1626.01 50.1143 1650.89C55.0648 1691.38 71.5287 1758.66 122.302 1846.6C173.086 1934.56 223.303 1982.77 256.036 2007.54C276.129 2022.75 302.67 2014.47 315.274 1992.65Z"
          fill={color2[0]}
        />
        <path
          d="M2290.08 1616L1893.52 1615.93C1871.97 1615.93 1858.5 1639.26 1869.28 1657.92L2067.77 2001.58C2080.31 2023.29 2106.66 2031.59 2126.69 2016.52C2159.28 1991.99 2209.31 1944.09 2260.09 1856.15C2310.87 1768.19 2327.5 1700.6 2332.6 1659.86C2335.72 1634.85 2315.28 1616.01 2290.08 1616Z"
          fill={color2[0]}
        />
        <path
          d="M97.8182 852.495L494.375 852.566C515.927 852.57 529.397 829.239 518.618 810.576L320.127 466.922C307.588 445.213 281.236 436.905 261.207 451.982C228.617 476.514 178.585 524.411 127.811 612.354C77.0277 700.314 60.3923 767.904 55.3013 808.64C52.1763 833.646 72.618 852.491 97.8182 852.495Z"
          fill={color2[0]}
        />
      </svg>
    </div>
  )
}

export default HomeEth
