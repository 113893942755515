import Arrow from "@components/icons/Arrow"
import { DoubleText } from "@components/ui"
import Link from "next/link"

export const section1 = (
  <>
    <div>
      <h1 className="!mb-0">
        <DoubleText size="text-normal" inactive logoText="One-click checkout" />
      </h1>
      <p className="font-medium !mt-2 text-lg text-muted-foreground">
        The fastest checkout, ever.
      </p>
    </div>
    <p>
      We reimagined the purchase process to make it seamless and instant. No
      more sign ups and tedious payment details.
    </p>
    <p>Just pay.</p>
  </>
)

export const section2 = (
  <>
    <div>
      <h1 className="!mb-0">
        <DoubleText size="text-normal" inactive logoText="Onchain is better" />
      </h1>
      <p className="font-medium !mt-2 text-lg text-muted-foreground">
        Simple. Efficient. Secure. Limitless.
      </p>
    </div>
    <p>
      Slice uses blockchain technology to provide unparalleled security for
      every purchase, {/* <Link href="#perks" className="highlight"> */}
      several perks {/* </Link>{" "} */}
      and unlimited flexibility.
    </p>
    <p className="mb-12">
      Embrace the power of decentralization. Experience true ownership and
      control over your business. For free.
    </p>
  </>
)

export const section3 = (
  <>
    <div>
      <h1 className="!mb-0">
        <DoubleText size="text-normal" inactive logoText="Smart payments" />
      </h1>
      <p className="font-medium !mt-2 text-lg text-muted-foreground">
        Get over the limits of traditional payments.
      </p>
    </div>
    <p>
      Revenue splitting, instant settlement, multi-currency prices, advanced
      discounts, token-gating, NFT minting, custom actions on Ethereum, and
      more.
    </p>
    <p>
      You have full control of your store, so you can make anything happen when
      products are purchased. Transparent and trustless.
    </p>
  </>
)

export const section4 = (
  <>
    <h1>
      Hold slices, <DoubleText size="text-normal" inactive logoText="earn Ξ" />
    </h1>
    <p>
      Payments are split by slicers transparently, which makes slices{" "}
      <b>the first tradable tokens with an objective value</b>.
    </p>
    <p>
      This opens up to many exciting use cases, with slicers acting as an{" "}
      <b>independent, decentralized payments infrastructure</b> and counterpart
      to real-world applications.
    </p>
  </>
)
