const Ethereum = ({ ...props }) => {
  return (
    <svg {...props}>
      <path
        d="M303.843 229.467L302.943 232.525V321.251L303.843 322.149L345.028 297.805L303.843 229.467Z"
        fill="#B8FAF6"
      />
      <path
        d="M303.842 229.467L262.656 297.805L303.842 322.149V279.084V229.467Z"
        fill="#F0CDC2"
      />
      <path
        d="M303.843 329.95L303.336 330.569V362.175L303.843 363.656L345.053 305.618L303.843 329.95Z"
        fill="#C8B1F4"
      />
      <path
        d="M303.842 363.656V329.95L262.656 305.618L303.842 363.656Z"
        fill="#F0CDC2"
      />
      <path
        d="M303.844 322.148L345.028 297.803L303.844 279.083V322.148Z"
        fill="#C9B3F5"
      />
      <path
        d="M262.656 297.803L303.842 322.148V279.083L262.656 297.803Z"
        fill="#88AAF1"
      />
    </svg>
  )
}

export default Ethereum
