import { domain } from "@components/common/Head"

const OP = ({ ...props }) => {
  return (
    <image
      href={`${domain}/opChain.png`}
      x="240"
      y="234"
      width="128"
      height="128"
    />
  )
}

export default OP
